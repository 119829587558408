.loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid rgb(22, 120, 158); /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2rem;
	animation: spin 0.7s linear infinite;
	box-shadow: 0 6px 8px 0 rgba(218, 218, 218, 0.2);
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
